import * as React from 'react'
import * as Rebass from 'rebass'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'

import { Layout } from '../components/layout'
import SEO from '../components/seo'

export default (...args) => {
  return (
    <Layout>
      <SEO title={`Tag: ''`} />
      <Rebass.Flex></Rebass.Flex>
    </Layout>
  )
}

// export const query = graphql`
//   query($slug: String!) {
//     contentfulTag(slug: { eq: $slug }) {
//       title
//       id
//       slug
//       post {
//         id
//         title
//         slug
//         publishDate(formatString: "MMMM DD, YYYY")
//         publishDateISO: publishDate(formatString: "YYYY-MM-DD")
//         heroImage {
//           title
//           fluid(maxWidth: 1800) {
//             ...GatsbyContentfulFluid_withWebp_noBase64
//           }
//         }
//         body {
//           childMarkdownRemark {
//             timeToRead
//             html
//             excerpt(pruneLength: 80)
//           }
//         }
//       }
//     }
//   }
// `
